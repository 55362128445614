<template>
    <div class="checkoutBillToPax">
        <div class="sidebarRow billToPaxRow">
           <h5 class="sidebarStepTitle">Dati di fatturazione</h5>
        </div>
        <div class="sidebarRow billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" v-model="guestRagioneSociale" ref="guestRagioneSociale" type="text" placeholder="Ragione Sociale" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestRagioneSociale')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Ragione sociale'" />
            </div>
        </div>

        <!-- codice fiscale e partita iva sono obbligatori ma si escludono a vicenda: compilando uno resetto l'altro -->
        <div class="sidebarRow billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestFiscalCode" ref="guestFiscalCode" type="text" placeholder="Codice Fiscale *" autocomplete="fiscal-code" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestFiscalCode')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Codice fiscale'" />
            </div>
        </div>

        <!-- se non sono in SSO aggiungo partita iva e codice destinatario (sdi) -->
        <div class="sidebarRow billToPaxRow" v-if="!mixinGetIntegrationInfo().sso">



            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestVat" ref="guestVat" type="text" placeholder="P. IVA" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestVat')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Partita Iva'" />
            </div>
        </div>

        <!-- se non sono in SSO aggiungo partita iva e codice destinatario (sdi) -->
        <div class="sidebarRow billToPaxRow" v-if="!mixinGetIntegrationInfo().sso">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestSdi" ref="guestSdi" type="text" placeholder="Codice SDI" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestSdi')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Codice destinatario SDI'" />
            </div>
        </div>

        <div class="sidebarRow billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestAddress" ref="guestAddress" type="text" placeholder="Indirizzo *" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestAddress')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Inserisci la via ed il numero civico'" />
            </div>
        </div>
        <div class="sidebarRow billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestCity" ref="guestCity" type="text" placeholder="Città *" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestCity')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Città'" />
            </div>
        </div>
        <div class="sidebarRow billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestPostalCode" ref="guestPostalCode" type="text" placeholder="CAP *" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestPostalCode')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Codice avviamento postale'" />
            </div>
        </div>
        <div class="sidebarRow billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestProvince" ref="guestProvince" type="text" placeholder="Provincia *" autocomplete="province"/>
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestProvince')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Sigla provincia'" />
            </div>
        </div>

    </div>
</template>

<script>
import SvgInfoCircle from './../../svg/info-circle'

export default {
    name: 'checkoutBillToPax',
    components:
    {
        SvgInfoCircle,
    },
    props:
    {
        inputSize: String
    },
    mounted()
    {
        this.validateBlock()
    },
    data()
    {
        return {
            guestRagioneSociale: (process.env.VUE_APP_MODE == 'development')? 'Dottor' : '',
            guestAddress: (process.env.VUE_APP_MODE == 'development')? 'via Clementina, 18' : '',
            guestCity: (process.env.VUE_APP_MODE == 'development')? 'Jesi' : '',
            guestPostalCode: (process.env.VUE_APP_MODE == 'development')? '60035' : '',
            guestProvince: (process.env.VUE_APP_MODE == 'development')? 'AN' : '',
            guestFiscalCode: (process.env.VUE_APP_MODE == 'development')? 'GRNMHL86L11C615X' : '',
            guestVat: (process.env.VUE_APP_MODE == 'development')? '1234567' : '',
            guestSdi: (process.env.VUE_APP_MODE == 'development')? '1234' : '',
        }
    },
    computed:
    {
    },
    methods:
    {
        clearField(ref)
        {
            this[ref] = ''
            this.$refs[ref].focus()
            this.validateBlock()
        },
        validateBlock()
        {
            let valid = this.mixinValidateFields([
                {
                    ref: this.$refs['guestAddress'],
                    type: 'address',
                    value: this.guestAddress,
                },
                {
                    ref: this.$refs['guestCity'],
                    type: 'city',
                    value: this.guestCity,
                },
                {
                    ref: this.$refs['guestPostalCode'],
                    type: 'postalCode',
                    value: this.guestPostalCode,
                },
                {
                    ref: this.$refs['guestProvince'],
                    type: 'province',
                    value: this.guestProvince,
                },
                {
                    ref: this.$refs['guestFiscalCode'],
                    type: 'fiscalCode',
                    value: this.guestFiscalCode,
                    //optional: true,
                    orValidate: [{
                      ref: this.$refs['guestVat'],
                      type: 'vat',
                      value: this.guestVat,
                    }], // non usare optional: true con orValidate
                },
            ])

            if(!this.mixinGetIntegrationInfo().sso)
            {
                valid = valid && this.mixinValidateFields([
                    {
                        ref: this.$refs['guestVat'],
                        type: 'vat',
                        value: this.guestVat,
                        //optional: true,
                        orValidate: [{
                          ref: this.$refs['guestFiscalCode'],
                          type: 'fiscalCode',
                          value: this.guestFiscalCode,
                        }],
                    },
                    /*
                    {
                        ref: this.$refs['guestSdi'],
                        type: 'sdi',
                        value: this.guestSdi,
                    },
                    */
                ])
            }

            this.$emit('sidebarBlockChanged',{
                name: 'checkoutBillToPax',
                status: valid,
                fields: {
                    'guestRagioneSociale': this.guestRagioneSociale,
                    'guestAddress': this.guestAddress,
                    'guestCity': this.guestCity,
                    'guestPostalCode': this.guestPostalCode,
                    'guestProvince': this.guestProvince,
                    'guestFiscalCode': this.guestFiscalCode,
                    'guestVat': this.guestVat,
                    'guestSdi': this.guestSdi,
                }
            })

            return valid
        },
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .checkoutBillToPax
    {
    }
</style>