var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkoutBillToPax" }, [
    _vm._m(0),
    _c("div", { staticClass: "sidebarRow billToPaxRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestRagioneSociale",
            attrs: {
              size: _vm.inputSize,
              type: "text",
              placeholder: "Ragione Sociale",
            },
            model: {
              value: _vm.guestRagioneSociale,
              callback: function ($$v) {
                _vm.guestRagioneSociale = $$v
              },
              expression: "guestRagioneSociale",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestRagioneSociale")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "Ragione sociale",
                expression: "'Ragione sociale'",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "sidebarRow billToPaxRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestFiscalCode",
            attrs: {
              size: _vm.inputSize,
              type: "text",
              placeholder: "Codice Fiscale *",
              autocomplete: "fiscal-code",
            },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestFiscalCode,
              callback: function ($$v) {
                _vm.guestFiscalCode = $$v
              },
              expression: "guestFiscalCode",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestFiscalCode")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "Codice fiscale",
                expression: "'Codice fiscale'",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
    !_vm.mixinGetIntegrationInfo().sso
      ? _c("div", { staticClass: "sidebarRow billToPaxRow" }, [
          _c(
            "div",
            { staticClass: "customInputBlock" },
            [
              _c("b-form-input", {
                ref: "guestVat",
                attrs: {
                  size: _vm.inputSize,
                  type: "text",
                  placeholder: "P. IVA",
                },
                on: {
                  input: function ($event) {
                    return _vm.validateBlock()
                  },
                },
                model: {
                  value: _vm.guestVat,
                  callback: function ($$v) {
                    _vm.guestVat = $$v
                  },
                  expression: "guestVat",
                },
              }),
              _c("span", { staticClass: "formError" }),
              _c("span", {
                staticClass: "formClear",
                on: {
                  click: function ($event) {
                    return _vm.clearField("guestVat")
                  },
                },
              }),
              _c("SvgInfoCircle", {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value: "Partita Iva",
                    expression: "'Partita Iva'",
                    modifiers: { hover: true, top: true },
                  },
                ],
                staticClass: "svgInfo cursor-pointer",
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.mixinGetIntegrationInfo().sso
      ? _c("div", { staticClass: "sidebarRow billToPaxRow" }, [
          _c(
            "div",
            { staticClass: "customInputBlock" },
            [
              _c("b-form-input", {
                ref: "guestSdi",
                attrs: {
                  size: _vm.inputSize,
                  type: "text",
                  placeholder: "Codice SDI",
                },
                on: {
                  input: function ($event) {
                    return _vm.validateBlock()
                  },
                },
                model: {
                  value: _vm.guestSdi,
                  callback: function ($$v) {
                    _vm.guestSdi = $$v
                  },
                  expression: "guestSdi",
                },
              }),
              _c("span", { staticClass: "formError" }),
              _c("span", {
                staticClass: "formClear",
                on: {
                  click: function ($event) {
                    return _vm.clearField("guestSdi")
                  },
                },
              }),
              _c("SvgInfoCircle", {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value: "Codice destinatario SDI",
                    expression: "'Codice destinatario SDI'",
                    modifiers: { hover: true, top: true },
                  },
                ],
                staticClass: "svgInfo cursor-pointer",
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "sidebarRow billToPaxRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestAddress",
            attrs: {
              size: _vm.inputSize,
              type: "text",
              placeholder: "Indirizzo *",
            },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestAddress,
              callback: function ($$v) {
                _vm.guestAddress = $$v
              },
              expression: "guestAddress",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestAddress")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "Inserisci la via ed il numero civico",
                expression: "'Inserisci la via ed il numero civico'",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "sidebarRow billToPaxRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestCity",
            attrs: {
              size: _vm.inputSize,
              type: "text",
              placeholder: "Città *",
            },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestCity,
              callback: function ($$v) {
                _vm.guestCity = $$v
              },
              expression: "guestCity",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestCity")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "Città",
                expression: "'Città'",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "sidebarRow billToPaxRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestPostalCode",
            attrs: { size: _vm.inputSize, type: "text", placeholder: "CAP *" },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestPostalCode,
              callback: function ($$v) {
                _vm.guestPostalCode = $$v
              },
              expression: "guestPostalCode",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestPostalCode")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "Codice avviamento postale",
                expression: "'Codice avviamento postale'",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "sidebarRow billToPaxRow" }, [
      _c(
        "div",
        { staticClass: "customInputBlock" },
        [
          _c("b-form-input", {
            ref: "guestProvince",
            attrs: {
              size: _vm.inputSize,
              type: "text",
              placeholder: "Provincia *",
              autocomplete: "province",
            },
            on: {
              input: function ($event) {
                return _vm.validateBlock()
              },
            },
            model: {
              value: _vm.guestProvince,
              callback: function ($$v) {
                _vm.guestProvince = $$v
              },
              expression: "guestProvince",
            },
          }),
          _c("span", { staticClass: "formError" }),
          _c("span", {
            staticClass: "formClear",
            on: {
              click: function ($event) {
                return _vm.clearField("guestProvince")
              },
            },
          }),
          _c("SvgInfoCircle", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value: "Sigla provincia",
                expression: "'Sigla provincia'",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "svgInfo cursor-pointer",
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebarRow billToPaxRow" }, [
      _c("h5", { staticClass: "sidebarStepTitle" }, [
        _vm._v("Dati di fatturazione"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }