<template>
    <div class="stripe">
        <div class="sidebarRow">
            <span>Paga con carta di credito</span><strong>{{amount}} €</strong>
        </div>
        <div class="sidebarRow mb-0">
            <div class="stripeContainer">
                <input :class="'form-control mb-2 ' + validNameClass" ref="ccname" v-model="name" placeholder="Titolare della carta">
                <div class="form-control">
                    <div ref="card" id="card"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from './../../api'

    export default {
        name: 'stripe',
        props:
        {
            amount: Number
        },
        data(){
            return {
                stripePublicKey: this.$config.stripePublicKey,
                stripe: '',
                stripeElements: '',
                stripeCardElement: '',
                stripeCardSelector: '#card',
                intentClientSecret: '',
                paymentIntentId: '',
                name: (process.env.VUE_APP_MODE == 'development')? 'Pimpa Calippa' : '',
            }
        },
        mounted()
        {
            this.stripe = window.Stripe(this.stripePublicKey)
            this.stripeElements = this.stripe.elements({
                family: 'Montserrat',
                src: 'url(https://fonts.googleapis.com/css?family=Montserrat:300)',
                weight: '300',
            })
            this.stripeCardElement = this.stripeElements.create('card',{
                hidePostalCode: true,
                style:
                {
                    base:{
                        backgroundColor: '#ffffff',
                        color: '#000000',
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: '18px',
                        fontSmoothing: 'antialiased',
                        //fontStyle,
                        //fontVariant,
                        fontWeight: 300,
                        //iconColor,
                        //lineHeight,
                        //letterSpacing,
                        //textAlign,
                        //padding: '16px',
                        //textDecoration,
                        //textShadow,
                        //textTransform,
                    },
                    complete: {
                        color: 'green'
                    },
                    invalid: {
                        color: 'red'
                    },
                }
            })
            this.stripeCardElement.mount(this.stripeCardSelector)

            let _this = this

            this.stripeCardElement.addEventListener('change', function (event) {
                if (event.complete && _this.name != '') {
                    _this.$emit('stripe-change', true)
                } else if (event.error) {
                    _this.$emit('stripe-change', false)
                }
            })
        },
        methods:
        {
            cardElementChanged(e)
            {
                window.console.log(e)
            },
            getIntentClientSecret()
            {
                Api
                    .stripeCreateIntentClientSecret({
                        token: this.$config.token,
                        amount: this.amount
                    })
                    .then((data) => {
                        this.intentClientSecret = data.intentClientSecret
                        this.confirmCardPayment()
                    })
            },
            confirmCardPayment()
            {
                let _this = this
                this.stripe.confirmCardPayment(
                    this.intentClientSecret,
                    {
                        payment_method: {
                        card: this.stripeCardElement,
                        billing_details: {
                                name: this.name
                            }
                        }
                    }
                ).then(function(result) {
                    if(result.error)
                    {
                        // cc test errori: 4000 0000 0000 0002
                        result.error.status = 'stripe_error'
                        _this.$emit('stripe-confirm',{error:true,result:result.error})
                        return
                    }
                    _this.paymentIntentId = result.paymentIntent.id
                    _this.$emit('stripe-confirm',{error:false,paymentIntentId: _this.paymentIntentId, name:_this.name})
                })
            }
        },
        computed: {
          validNameClass(){
            return (this.name != '' && this.name.length > 3)? 'valid' : 'invalid'
          }
        }
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    .stripe
    {
        width:100%;
        .stripeContainer
        {
            display:block;
            width:100%;
        }
    }

</style>