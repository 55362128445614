var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stripe" }, [
    _c("div", { staticClass: "sidebarRow" }, [
      _c("span", [_vm._v("Paga con carta di credito")]),
      _c("strong", [_vm._v(_vm._s(_vm.amount) + " €")]),
    ]),
    _c("div", { staticClass: "sidebarRow mb-0" }, [
      _c("div", { staticClass: "stripeContainer" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name",
            },
          ],
          ref: "ccname",
          class: "form-control mb-2 " + _vm.validNameClass,
          attrs: { placeholder: "Titolare della carta" },
          domProps: { value: _vm.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            },
          },
        }),
        _c("div", { staticClass: "form-control" }, [
          _c("div", { ref: "card", attrs: { id: "card" } }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }